import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';

import ProgressionInfo from 'components/ProgressionInfo';
import ChordVisualizer from 'components/ChordVisualizer';
import PlayStopButton from 'components/PlayStopButton';
import Separator from 'components/Separator';
import { Row, RowStart } from 'components/Common/Layout';
import ErrorBoundary from 'components/ErrorBoundary';

import Link from 'components/Link';
import Message from 'components/Message';
import { DummyTitle, DummySettings } from 'components/LoadingView';
import {
  Text,
  Title,
  SecondaryHierarchyText,
} from 'components/Common/Typography';
import { BlockSpace, InlineSpace } from 'components/Common/Spacing';
import NumberRange from 'components/NumberRange';
import CustomCheckBox from 'components/CustomCheckBox';

import { TERTIARY, PLACEHOLDER } from 'design-system/inventory/heirarchy';
import {
  classNamesHelper,
  formatTime,
  getShortTitle,
  debounceInput,
} from 'utils';

import {
  transposeProgression,
  parseProgression,
  setSyncChanges,
} from 'actions/progression-settings';
import { initProgression } from 'actions/player';

import useCurrentInstrument from 'hooks/use-current-instrument';
import useHandleChordPlay from 'hooks/use-handle-chord-play';

import { STATUS_SUCCESS } from 'types/app';

import styles from './AudioPlayer.css';

export default function AudioPlayer() {
  useStyles(styles);
  useHandleChordPlay();
  const dispatch = useDispatch();
  const { instrument, status: instrumentStatus } = useCurrentInstrument();

  const { currentProgression, parsed } = useSelector(
    state => state.progression,
  );
  const { loop } = useSelector(state => state.progressionSettings);
  const playerState = useSelector(state => state.player);
  const { theme } = useSelector(state => state.app);

  const { progressionParts } = playerState.progressionPlayer;
  const endTime = progressionParts.length
    ? Math.round(progressionParts[progressionParts.length - 1].time, 2)
    : 0;

  const lastNoteLength = progressionParts.length
    ? Math.round(progressionParts[progressionParts.length - 1].noteLength, 2)
    : 0;

  const onTransposeChange = debounceInput(async transposeValue => {
    await dispatch(
      transposeProgression({
        progressionText: currentProgression?.originalProgressionText,
        transpose: transposeValue,
      }),
    );
    await dispatch(parseProgression());
    dispatch(initProgression());
  });

  const onBPMChange = debounceInput(bpm => {
    dispatch(
      setSyncChanges({
        bpm,
      }),
    );
    dispatch(initProgression());
  });

  const onLoopChanged = isChecked => {
    dispatch(
      setSyncChanges({
        loop: isChecked,
      }),
    );
    dispatch(initProgression());
  };

  const isProgressionLoading =
    isNil(currentProgression) || currentProgression?.loading;

  /* eslint css-modules/no-unused-class: off */
  // temp
  /* eslint css-modules/no-undef-class: off */
  return (
    <div className={classNamesHelper([styles.container, styles[theme]])}>
      <div className={styles['player-container']}>
        <div className={styles['player-header']}>
          {parsed && parsed.error && (
            <Message messageType="error">Prase Error</Message>
          )}
          {isProgressionLoading && <DummyTitle />}
          <Title>{getShortTitle(currentProgression?.name)}</Title>
          <BlockSpace size={50} />
          <Row>
            {!isProgressionLoading && <ProgressionInfo />}
            {!isProgressionLoading && <Separator />}
            {!isProgressionLoading ? (
              <Link to={`/p/${currentProgression.slug}/edit`}>
                <Text heirarchy={TERTIARY}>Editor</Text>
              </Link>
            ) : (
              <>
                <DummySettings />
                <DummySettings />
              </>
            )}
          </Row>
        </div>
      </div>
      {/* {isNil(currentProgression?.settings) ? (
        <>
          <div>
            <DummySettings />
            <DummySettings />
          </div>
          <div>
            <DummySettings />
            <DummySettings />
          </div>
        </>
      ) : (
        <div>
          <div>
            <SecondaryHierarchyText>
              Octave: {currentProgression?.settings?.octave}
            </SecondaryHierarchyText>
          </div>
          <div>
            <SecondaryHierarchyText>
              BPM: {currentProgression?.settings?.bpm}
            </SecondaryHierarchyText>
          </div>
          {currentProgression?.settings?.arpeggiateStyle && (
            <div>
              <SecondaryHierarchyText>
                Arpeggio: {currentProgression?.settings?.arpeggiateStyle}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.customArpeggio && (
            <div>
              <SecondaryHierarchyText>
                Custom Arpeggio: {currentProgression?.settings?.customArpeggio}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.time && (
            <div>
              <SecondaryHierarchyText>
                Timing: {currentProgression?.settings?.time}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.timeSignature && (
            <div>
              <SecondaryHierarchyText>
                Time Signature: {currentProgression?.settings?.timeSignature}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.rhythm && (
            <div>
              <SecondaryHierarchyText>
                Rhythm: {currentProgression?.settings?.rhythm}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.ADSR && (
            <div>
              <SecondaryHierarchyText>
                ADSR: {currentProgression?.settings?.ADSR}
              </SecondaryHierarchyText>
            </div>
          )}
          {currentProgression?.settings?.IADSR && (
            <div>
              <SecondaryHierarchyText>
                IADSR: {currentProgression?.settings?.IADSR}
              </SecondaryHierarchyText>
            </div>
          )}
        </div>
      )} */}
      <BlockSpace size={50} />
      <div>
        {isNil(currentProgression?.settings) ? (
          <>
            <DummySettings />
            <DummySettings />
          </>
        ) : (
          <>
            {instrument && instrument !== 'web-audio' && (
              <SecondaryHierarchyText>
                {instrument}{' '}
                {instrumentStatus === STATUS_SUCCESS ? '' : 'loading...'}
              </SecondaryHierarchyText>
            )}
            {instrument === 'web-audio' && (
              <SecondaryHierarchyText>Web Audio</SecondaryHierarchyText>
            )}
          </>
        )}
      </div>
      <div className={styles['chord-visual-wrapper']}>
        <ChordVisualizer />
      </div>
      {/* currentProgression?._id && progressionSettings?.loop && (
        <div className={styles['loop-count-container']}>
          <span>Loop: </span>
          <span id="loopCount">{playerState.loopCount}</span>
        </div>
      ) */}

      {isNil(currentProgression) ? (
        <>
          <DummySettings />
          <DummySettings />
        </>
      ) : (
        currentProgression && (
          <div className={styles['chord-start-end-time']}>
            <Text heirarchy={PLACEHOLDER}>
              {formatTime(playerState.currentTime)}
            </Text>
            <Text heirarchy={PLACEHOLDER}>&nbsp;-&nbsp;</Text>
            <Text heirarchy={PLACEHOLDER}>
              {formatTime(endTime + lastNoteLength)}
            </Text>
          </div>
        )
      )}
      <BlockSpace size={50} />
      <RowStart>
        <ErrorBoundary>
          <PlayStopButton />
        </ErrorBoundary>
        <InlineSpace size={12} />
        {!isNil(currentProgression?.settings?.transpose) ? (
          <NumberRange
            name="transposeProgression"
            label="Transpose"
            value={currentProgression?.settings?.transpose}
            range={[-11, 11]}
            onChange={onTransposeChange}
          />
        ) : (
          <>
            <DummySettings />
            <DummySettings />
            <DummySettings />
          </>
        )}
        <InlineSpace size={12} />
        {!isNil(currentProgression?.settings?.bpm) && (
          <NumberRange
            name="BPM"
            label="BPM"
            hideButtons
            range={[1, Infinity]}
            value={currentProgression?.settings?.bpm}
            onChange={onBPMChange}
          />
        )}
        <InlineSpace size={12} />
        {!isNil(currentProgression?.settings?.loop) && (
          <CustomCheckBox
            name="loop"
            label="Loop"
            isSelected={loop}
            onChange={e => onLoopChanged(e.target.checked)}
          />
        )}
      </RowStart>
    </div>
  );
}
