import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import LoadingView from 'components/LoadingView';
import AudioPlayer from 'components/AudioPlayer';

import {
  fetch as fetchProgression,
  resetCurrentProgression,
} from 'actions/progression';
import {
  setSyncChanges,
  resetProgressionSettings,
} from 'actions/progression-settings';
import { initProgression, clearPlayer } from 'actions/player';
import { FETCH_FAILURE } from 'types/progression';

import styles from './Player.css';

const loadingOtions = {
  fallback: <LoadingView />,
  ssr: true,
};

const NotFoundContainerLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'containers-NotFound' */ 'containers/NotFound'),
  loadingOtions,
);

export default function Player({ params, backgroundPage }) {
  useStyles(styles);
  const dispatch = useDispatch();
  const progressionSettings = useSelector(state => state.progressionSettings);
  const { entityProfile } = useSelector(state => state.user);
  const { samplerConfig, progressionPlayer } = useSelector(
    state => state.player,
  );
  const {
    fetchResponseStatus: progressionFetchResponseStatus,
    fetchError: progressionFetchError,
  } = useSelector(state => state.progression);

  const getProgression = async () => {
    const queryOptions = {};
    if (backgroundPage === 'user' && entityProfile?._id) {
      queryOptions.user = entityProfile._id;
    }
    const fetchResponse = await dispatch(
      fetchProgression(params.progressionSlug, queryOptions),
    );
    if (fetchResponse.type === FETCH_FAILURE) {
      return;
    }
    const {
      payload: { parsed },
    } = fetchResponse;
    if (parsed.error) {
      return;
    }
    dispatch(
      setSyncChanges({
        parsedProgression: parsed.progression,
        parsedLines: parsed.lines,
        delays: parsed.delays,
      }),
    );

    dispatch(initProgression());
  };

  useEffect(() => {
    if (params.progressionSlug) {
      getProgression();
    }
    return function cleanup() {
      // eslint-disable-next-line no-unused-expressions
      progressionPlayer?.clear();
      // eslint-disable-next-line no-unused-expressions
      progressionPlayer?.clearSampler();
      dispatch(resetCurrentProgression());
      dispatch(clearPlayer());
      dispatch(resetProgressionSettings());
    };
  }, [params.progressionSlug]);

  useEffect(() => {
    if (!progressionSettings.parsedProgression) return;
    dispatch(initProgression());
  }, [progressionSettings.instrument, samplerConfig]);

  if (progressionFetchError && progressionFetchResponseStatus === 404) {
    return <NotFoundContainerLoadable />;
  }

  return <AudioPlayer />;
}

Player.propTypes = {
  params: PropTypes.object.isRequired,
  backgroundPage: PropTypes.string,
};

Player.defaultProps = {
  backgroundPage: '',
};
