// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._3AqAG{position:relative;height:100%}.m2sb1{margin:.2em 0}._2NKv8{margin-bottom:1em;padding-bottom:1em}._1UeD9{margin-top:0}._2pLc7{color:var(--label-color)}._37dwi,._20_B5{display:inline-block}", ""]);
// Exports
exports.locals = {
	"container": "_3AqAG",
	"chord-visual-wrapper": "m2sb1",
	"player-header": "_2NKv8",
	"title": "_1UeD9",
	"chord-start-end-time": "_2pLc7",
	"end-time": "_37dwi",
	"start-time": "_20_B5"
};
module.exports = exports;
