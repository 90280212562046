import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSyncChanges } from 'actions/player';

export default function useHandleChordPlay() {
  const dispatch = useDispatch();
  const progressionSettings = useSelector(state => state.progressionSettings);
  const playerState = useSelector(state => state.player);

  function stopProgression() {
    playerState.progressionPlayer.stopProgression();
    dispatch(
      setSyncChanges({
        isPlaying: false,
        completed: true,
        loopCount: 0,
      }),
    );
  }

  function checkCompleted({ chordOrNoteNumber }) {
    if (chordOrNoteNumber === playerState.totalChords - 1) {
      if (progressionSettings.loop === false) {
        stopProgression();
        return;
      }
      dispatch(
        setSyncChanges({
          loopCount: playerState.loopCount + 1,
          completed: true,
        }),
      );
    }
  }

  function handleChordPlay(data) {
    dispatch(
      setSyncChanges({
        currentTime: data.time,
        prevIndex: data.index,
      }),
    );
    if (
      progressionSettings.arpeggiateStyle ||
      progressionSettings.customArpeggio
    ) {
      // playerOptions.progress = data.noteNumber;
      dispatch(
        setSyncChanges({
          progress: data.index,
        }),
      );

      checkCompleted({ data, chordOrNoteNumber: data.noteNumber });
      return;
    }

    dispatch(
      setSyncChanges({
        progress: data.index,
      }),
    );
    // activateCurrentChord({ progressionSettings, data});
    checkCompleted({ data, chordOrNoteNumber: data.index });
  }

  useEffect(() => {
    playerState.progressionPlayer.on('play:progression', handleChordPlay);
    return () => {
      playerState.progressionPlayer.removeListener(
        'play:progression',
        handleChordPlay,
      );
    };
  }, [playerState]);
}
