import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import nprogress from 'nprogress';

import SampleLibrary from 'lib/tonejs-instruments';

import {
  setInstrumentRequest,
  setInstrumentSuccess,
} from 'actions/progression-settings';
import usePlayer from 'hooks/use-player';
import { setSyncChanges as setPlayerSyncChanges } from 'actions/player';

export default function useCurrentInstrument() {
  const dispatch = useDispatch();
  const { currentProgression } = useSelector(state => state.progression);
  const progressionSettings = useSelector(state => state.progressionSettings);
  const { playerState } = usePlayer();

  const setInstrument = async instrument => {
    let samplerConfig = null;
    const instrumentValue = instrument;
    if (instrumentValue === 'web-audio' || instrumentValue === '') {
      dispatch(setInstrumentSuccess(instrumentValue));
      playerState.progressionPlayer.clearSampler();
      dispatch(
        setPlayerSyncChanges({
          samplerConfig,
        }),
      );
      return;
    }

    nprogress.start();
    dispatch(setInstrumentRequest());
    const baseUrl = window.App.assetsPath
      ? `${window.App.assetsPath}/samples/`
      : '/samples/';

    samplerConfig = SampleLibrary.load({
      ext: '.mp3',
      instruments: instrumentValue,
      baseUrl,
      onload: () => {
        nprogress.done();
        dispatch(setInstrumentSuccess(instrumentValue));
      },
    });
    playerState.progressionPlayer.setSampler(samplerConfig);
    dispatch(
      setPlayerSyncChanges({
        samplerConfig,
      }),
    );
  };

  useEffect(() => {
    if (currentProgression?.settings?.instrument) {
      setInstrument(currentProgression?.settings?.instrument);
    }
  }, [currentProgression?._id]);

  return {
    instrument: currentProgression?.settings?.instrument,
    status: progressionSettings.setInstrumentStatus,
    setInstrument,
  };
}
