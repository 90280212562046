import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import isEmpty from 'lodash/isEmpty';

// import { DummyDetail } from 'components/LoadingView';
// import EmptyMessage from 'components/Common/EmptyMessage';

import useTheme from 'selectors/use-theme';

import { classNamesHelper } from 'utils';

import styles from './ChordVisualizer.css';

export default function ChordVisualizer({ editorView }) {
  useStyles(styles);

  const theme = useTheme();
  const [activeChordPosition, setActiveChordPosition] = useState(null);
  const { parsedLines: chordLines } = useSelector(
    state => state.progressionSettings,
  );
  const { progressionPlayer } = useSelector(state => state.player);
  const { isFullScreen } = useSelector(state => state.app);
  const progressionSettings = useSelector(state => state.progressionSettings);

  function handleChordPlay(data) {
    if (data.chordPosition !== undefined) {
      setActiveChordPosition(data.chordPosition);
    }
  }

  useEffect(() => {
    setActiveChordPosition(null);
    progressionPlayer.removeListener('play:progression', handleChordPlay);
  }, [progressionSettings]);

  useEffect(() => {
    progressionPlayer.on('play:progression', handleChordPlay);
    return () => {
      progressionPlayer.removeListener('play:progression', handleChordPlay);
    };
  }, [progressionPlayer]);

  const mapLineItems = () => {
    return chordLines?.map((lineObject, index) => {
      if (lineObject?.isComment) {
        return (
          <div key={`lyric-${index}`}>
            <p className={styles.lyric}>{lineObject.comment}</p>
          </div>
        );
      }
      return (
        <div key={`visual-chords-list-${index}`}>
          {lineObject?.map((chord, chordIndex) => {
            return (
              <span
                key={`visual-chord-${chord.originalSymbol}-${chordIndex}`}
                className={classNamesHelper([
                  styles.chord,
                  activeChordPosition === chord.chordPosition
                    ? styles['chord-active']
                    : '',
                ])}
              >
                {chord.originalSymbol}
              </span>
            );
          })}
        </div>
      );
    });
  };

  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={classNamesHelper([
        styles.container,
        styles[theme],
        isFullScreen && styles['full-screen'],
      ])}
    >
      <div
        className={classNamesHelper([
          styles['progression-container'],
          editorView && styles['editor-view'],
          styles[theme],
        ])}
      >
        {mapLineItems()}
      </div>

      {/* isEmpty(chordLines) && (
        <div
          className={classNamesHelper([
            styles['progression-container'],
            styles[theme],
          ])}
        >
          <EmptyMessage>Chords are shown here.</EmptyMessage>
        </div>
      ) */}
    </div>
  );
}

ChordVisualizer.propTypes = {
  editorView: PropTypes.bool,
};

ChordVisualizer.defaultProps = {
  editorView: false,
};
